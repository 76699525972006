<template lang="">

<div v-if="rapport && rapport.infos_revendeur">
        <!-------------------------------- INFOS RAPPORT REVENDEUR -------------------------------->
      <v-col cols="12" sm="10" class="pb-0  mt-10">
        <v-toolbar color="#0B87BA" flat dense>
          <v-col
            cols="6"
            lg="6"
            xl="2"
            class="pa-0 font_color text-left"
          >
            INFOS RAPPORT REVENDEUR
          </v-col>
        </v-toolbar>
      </v-col>


      <v-col  v-if="rapport.type_visite !== 'APPEL'" cols="12" sm="10" class="pb-0">
            <div class="d-flex">
              <div class="text-left mt-5">NIVEAU D'ACTIVITE</div>
                <v-radio-group v-if="mode !== 'visualisation'" class="ml-10" row v-model="rapport.infos_revendeur.niveau_activite">
                  <v-radio label="EN BAISSE"  v-bind:value="'en baisse'"></v-radio>
                  <v-radio label="STABLE"  v-bind:value="'stable'" ></v-radio>
                  <v-radio label="EN HAUSSE"  v-bind:value="'en hausse'" ></v-radio>
                </v-radio-group>
            </div>
            <div v-if="mode == 'visualisation'" class="text-left font-weight-bold" style="overflow-wrap: break-word;"> {{rapport.infos_revendeur.niveau_activite}}</div>
      </v-col>



      <v-col cols="12" sm="10">
        <div class="text-left">COMMENTAIRES ET AVIS SUR LE SERVICE FTA</div>
        <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold" style="overflow-wrap: break-word;">{{rapport.infos_revendeur.avis_service_fta}}</div>
        <v-textarea  v-if="mode != 'visualisation'"
          outlined
          auto-grow
          rows="4"
          v-model="rapport.infos_revendeur.avis_service_fta"
        >
        
        </v-textarea>
        </v-col>

        <v-col cols="12" sm="10" class="pb-0">
            <div class="d-flex">
              <div class="text-left mt-5">UTILISATIONS CONFIGURATEURS</div>
                <v-radio-group v-if="mode !== 'visualisation'" class="ml-10" row v-model="rapport.infos_revendeur.utilisations_configurateurs">
                  <v-radio label="JAMAIS"  v-bind:value="'jamais'"></v-radio>
                  <v-radio label="IRREGULIERE"  v-bind:value="'irreguliere'" ></v-radio>
                  <v-radio label="REGULIERE"  v-bind:value="'reguliere'" ></v-radio>
                </v-radio-group>
            </div>
            <div v-if="mode == 'visualisation'" class="text-left font-weight-bold" style="overflow-wrap: break-word;"> {{rapport.infos_revendeur.utilisations_configurateurs}}</div>

            <div  class="d-flex">
              <div class="text-left">Pourquoi ?</div>
              <v-textarea  v-if="mode != 'visualisation'"
                outlined
                auto-grow
                rows="4"
                v-model="rapport.infos_revendeur.utilisations_configurateurs_pourquoi"
                class="ml-2"
              >
              
              </v-textarea>
              <div v-if="mode == 'visualisation'" class=" ml-2 text-left font-weight-bold" style="overflow-wrap: break-word;"> {{rapport.infos_revendeur.utilisations_configurateurs_pourquoi}}</div>

            </div>
        </v-col>

        <v-col cols="12" sm="10" class="pb-0">
            <div class="d-flex">
              <div class="text-left mt-5">UTILISATIONS STOCKS ET TARIFS</div>
                <v-radio-group v-if="mode !== 'visualisation'" class="ml-10" row v-model="rapport.infos_revendeur.utilisations_stocks_et_tarifs">
                  <v-radio label="JAMAIS"  v-bind:value="'jamais'"></v-radio>
                  <v-radio label="IRREGULIERE"  v-bind:value="'irreguliere'" ></v-radio>
                  <v-radio label="REGULIERE"  v-bind:value="'reguliere'" ></v-radio>
                </v-radio-group>
            </div>
            <div v-if="mode == 'visualisation'" class="text-left font-weight-bold" style="overflow-wrap: break-word;"> {{rapport.infos_revendeur.utilisations_stocks_et_tarifs}}</div>
            <div  class="d-flex">
              <div class="text-left">Pourquoi ?</div>
              <v-textarea  v-if="mode != 'visualisation'"
                outlined
                auto-grow
                rows="4"
                v-model="rapport.infos_revendeur.utilisations_stocks_et_tarifs_pourquoi"
                class="ml-2"
              >
              
              </v-textarea>
              <div v-if="mode == 'visualisation'" class=" ml-2 text-left font-weight-bold" style="overflow-wrap: break-word;"> {{rapport.infos_revendeur.utilisations_stocks_et_tarifs_pourquoi}}</div>
  
            </div>
        </v-col>

        <v-col cols="12" sm="10" class="pb-0">
            <div class="d-flex">
              <div class="text-left mt-5">MONTANT DU FRANCO DE PORT CONNU : 500€ PAR LIVRAISON</div>
                <v-radio-group v-if="mode !== 'visualisation'" class="ml-10" row v-model="rapport.infos_revendeur.montant_franco_connu">
                  <v-radio label="OUI"  v-bind:value="true"></v-radio>
                  <v-radio label="NON"  v-bind:value="false" ></v-radio>
                </v-radio-group>
            </div>
                            <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold">{{rapport.action_a_realiser==true?"Oui":"Non"}} </div>

        </v-col>

        <div class="mt-3">
                  <v-row>
          <v-col cols="12" sm="2" class="d-flex justify-center align-center ">
            <b>Familles</b>
          </v-col>

          <v-col cols="12" sm="1" class="d-flex ">
            <b>Pas de potentiel</b>
          </v-col>

          <v-col cols="12" sm="1" class="d-flex align-center">
            <b>A consolider</b>
          </v-col>

          <v-col cols="12" sm="1" class="d-flex align-center">
            <b>A développer</b>
          </v-col>

          <v-col cols="12" sm="6" class="d-flex justify-center align-center">
            <b>Actions pour développer</b>
          </v-col>

          
        </v-row>

        <v-container v-for="famille in rapport.infos_revendeur.familles_produits" :key="famille.id" >
        <v-row>
          <v-col cols="12" sm="2" class="d-flex align-center text-left">
            {{famille.libelle}}
          </v-col>

          <v-col cols="12" sm="1" class="d-flex align-center">
            <input v-if="mode !== 'visualisation'" type="checkbox" v-model="famille.pasDePotentiel" /> 
            <span v-else class="text-left font-weight-bold"> {{ famille.pasDePotentiel ? "OUI" : "NON"}} </span>
          </v-col>

          <v-col cols="12" sm="1" class="d-flex align-center">
            <input v-if="mode !== 'visualisation'" type="checkbox" v-model="famille.aConsolider" /> 
            <span v-else class="text-left font-weight-bold"> {{ famille.aConsolider ? "OUI" : "NON"}} </span>
          </v-col>

          <v-col cols="12" sm="1" class="d-flex align-center">
            <input v-if="mode !== 'visualisation'" type="checkbox" v-model="famille.aDevelopper" /> 
            <span v-else class="text-left font-weight-bold"> {{ famille.aDevelopper ? "OUI" : "NON"}} </span>
          </v-col>

          <v-col cols="12" sm="6" class="d-flex align-center">
            <!-- <v-text-field single-line outlined v-model="etape.commentaire" hide-details dense ></v-text-field> -->
            <v-textarea v-if="mode !== 'visualisation'" single-line outlined v-model="famille.actionsPourDevelopper" hide-details dense auto-grow row-height="5"></v-textarea>
            <span v-else class="font-italic justify-center align-center " style="word-wrap: anywhere; text-align: left;"><b v-if="famille.actionsPourDevelopper && famille.actionsPourDevelopper.length > 0"> "{{famille.actionsPourDevelopper}}"</b></span>
          </v-col>

        </v-row> 
        </v-container>
        </div>


              <v-col cols="12" sm="10">
        <div class="text-left">AVIS SUR LES NOUVEAUX PRODUITS OU SERVICES PRESENTES</div>
        <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold" style="overflow-wrap: break-word;">{{rapport.infos_revendeur.avis_produit_service}}</div>
        <v-textarea  v-if="mode != 'visualisation'"
          outlined
          auto-grow
          rows="4"
          v-model="rapport.infos_revendeur.avis_produit_service"
        >
        
        </v-textarea>
        </v-col>

              <v-col cols="12" sm="10">
        <div class="text-left">PROJETS EN COURS</div>
        <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold" style="overflow-wrap: break-word;">{{rapport.infos_revendeur.projets_en_cours}}</div>
        <v-textarea  v-if="mode != 'visualisation'"
          outlined
          auto-grow
          rows="4"
          v-model="rapport.infos_revendeur.projets_en_cours"
        >
        
        </v-textarea>
        </v-col>

              <v-col cols="12" sm="10">
        <div class="text-left">ACTIONS GENERALES POUR DEVELOPPER LE CA ( remise supplémentaire, formation technique, baisse du franco...)</div>
        <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold" style="overflow-wrap: break-word;">{{rapport.infos_revendeur.actions_generales}}</div>
        <v-textarea  v-if="mode != 'visualisation'"
          outlined
          auto-grow
          rows="4"
          v-model="rapport.infos_revendeur.actions_generales"
        >
        
        </v-textarea>
        </v-col>

              <v-col cols="12" sm="10">
        <div class="text-left">COMPLEMENTS D'INFORMATIONS POUR LES ACHATS</div>
        <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold" style="overflow-wrap: break-word;">{{rapport.infos_revendeur.complement_infos_achats}}</div>
        <v-textarea  v-if="mode != 'visualisation'"
          outlined
          auto-grow
          rows="4"
          v-model="rapport.infos_revendeur.complement_infos_achats"
        >
        
        </v-textarea>
        </v-col>



  

</div>

</template>
<script>
// import moment from "moment";

export default {
  props: {
    rapport: {
      type: Object,
      required: true,
    },
    mode: [String],
  },
  components: {},
  data() {
    return {
      rowOutilSpecifique: {},
      rowCartouche: {},
      // showOutilsForm: false,
      // showCartouchesForm: false,
    };
  },

  computed: {},
  watch: {
  },
  methods: {

  
  },
  async created() {

  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables";
.font_color {
  font-weight: bold;
  color: #ffffff;
}

.bloc_sub_item {
  background-color: #cfeffc;
  border-radius: 9px;
  width: 92%;

}
</style>
